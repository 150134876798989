.parkour-button__gradient {
	position: relative;
	z-index: 1;

	background-image: var(--parkour-button-gradient);

	&::before {
		--parkour-button-before-opacity: 0;

		content: "";
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;

		width: 100%;
		height: 100%;

		background-image: linear-gradient(to right, theme("colors.p-100"), theme("colors.p-100"));
		opacity: var(--parkour-button-before-opacity);

		transition: opacity 0.15s ease-in-out;
	}
}

.parkour-button__gradient--default {
	--parkour-button-gradient: linear-gradient(90deg, #7b6181 0%, #846186 14.29%, #8e618a 28.57%, #98608c 42.86%, #a35f8e 57.14%, #ae5d8f 71.43%, #ba5b8e 85.71%, #c5598d 100%);

	&.active,
	&:hover,
	&:focus-visible {
		&::before {
			--parkour-button-before-opacity: 1;
		}
	}
}

.parkour-button__gradient--quaternary {
	--parkour-button-gradient: linear-gradient(90deg, rgba(123, 97, 129, 0.20) 0%, rgba(129, 94, 130, 0.20) 14.29%, rgba(135, 90, 129, 0.20) 28.57%, rgba(142, 86, 128, 0.20) 42.86%, rgba(149, 81, 125, 0.20) 57.14%, rgba(156, 76, 122, 0.20) 71.43%, rgba(162, 71, 117, 0.20) 85.71%, rgba(169, 65, 111, 0.20) 100%);

	&.active {
		&::before {
			--parkour-button-before-opacity: 1;
		}
	}
}
