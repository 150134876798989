.popup {
	--background-opacity: 0.8;
	--width: 100%;
	--height: fit-content;
	--background: transparent;
	--box-shadow: none;

	animation: blur-in 0.3s forwards;
}

.popup::part(content) {
	@apply container-1-col container-space;
}

@keyframes blur-in {
	from {
		backdrop-filter: blur(0px);
	}
	to {
		backdrop-filter: blur(2px);
	}
}
