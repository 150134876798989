ion-checkbox {
	--size: 1.25rem;
	--checkmark-width: 0.1875rem;
	--checkmark-color: theme("colors.n-300");
	--checkbox-background-checked: theme("colors.positive");
	--border-color: theme("colors.n-500");
	--border-color-checked: theme("colors.positive");
	--border-radius: theme("borderRadius.sm");
	--border-width: 0.09375rem;
}

ion-checkbox::part(container) {
	padding: 0.125rem;
}

ion-checkbox::part(label) {
	margin-inline-start: 0.5rem;
}
