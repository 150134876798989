/* -------------------------------------------------------------------------- */
/* Utils */
/* -------------------------------------------------------------------------- */
@layer utilities {
	.card-hover-state-300 {
		@apply hover:bg-n-300/75 transition-colors has-[a:focus]:bg-n-300/75 has-[a:focus-visible]:outline-[3px] has-[a:focus-visible]:outline-[yellow] has-[a:focus-visible]:outline-dashed has-[a:focus-visible]:outline-offset-[2px];
	}

	.card-hover-state-400 {
		@apply hover:bg-n-400/75 transition-colors has-[a:focus]:bg-n-400/75 has-[a:focus-visible]:outline-[3px] has-[a:focus-visible]:outline-[yellow] has-[a:focus-visible]:outline-dashed has-[a:focus-visible]:outline-offset-[2px];
	}

	.card-hover-state-500 {
		@apply hover:bg-n-500/75 transition-colors has-[a:focus]:bg-n-500/75 has-[a:focus-visible]:outline-[3px] has-[a:focus-visible]:outline-[yellow] has-[a:focus-visible]:outline-dashed has-[a:focus-visible]:outline-offset-[2px];
	}

	.clickable-card-300 {
		@apply relative;
		@apply bg-n-300;
		@apply card-hover-state-500;
	}

	.clickable-card-200 {
		@apply relative;
		@apply bg-n-200;
		@apply card-hover-state-300;
	}

	.clickable-card-500 {
		@apply relative;
		@apply bg-n-500;
		@apply card-hover-state-300;
	}
}
