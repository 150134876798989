/* -------------------------------------------------------------------------- */
/* Header */
/* -------------------------------------------------------------------------- */
ion-header {
	box-shadow: none !important;
}

ion-toolbar {
	--border-width: 0 !important;
	--padding-start: 0;
	--padding-end: 0;
	--padding-top: 0;
	--padding-bottom: 0;
	--min-height: rem(44px);
}

/* -------------------------------------------------------------------------- */
/* Back button */
/* -------------------------------------------------------------------------- */
ion-button,
ion-buttons,
ion-back-button {
	--ripple-color: transparent;

	overflow: visible;
}

ion-back-button {
	&::part(icon) {
		font-size: rem(16px);
	}
}

/* -------------------------------------------------------------------------- */
/* title */
/* -------------------------------------------------------------------------- */
ion-toolbar ion-title {
	@apply typo-body variant-body-fat;
}

/* -------------------------------------------------------------------------- */
/* Button */
/* -------------------------------------------------------------------------- */
ion-button,
ion-buttons {
	margin-inline: 0 !important;
}

ion-button {
	@media screen(md) {
		--padding-start: rem(16px) !important;
		--padding-end: rem(16px) !important;
	}
}

ion-back-button::part(native),
ion-button::part(native) {
	--ion-button-width: auto;
	--ion-button-height: auto;

	width: var(--ion-button-width);
	height: var(--ion-button-height);
	margin: 0;

	border-radius: 0;

	&::after {
		transition: background 0.15s ease-in-out, opacity 0.15s ease-in-out;
	}
}

.ios ion-button::part(native) {
	--ion-button-width: rem(44px);
	--ion-button-height: rem(44px);

	@media screen(md) {
		--ion-button-width: auto;
	}
}

.md ion-button::part(native) {
	--ion-button-width: rem(56px);
	--ion-button-height: rem(56px);

	@media screen(md) {
		--ion-button-width: auto;
	}
}

ion-button:not(.back-button)::part(native) {
	background: theme("colors.n-0-rgb-2");
}

.ios ion-back-button::part(native) {
	--ion-button-width: rem(44px);
	--ion-button-height: rem(44px);
}

.md ion-back-button::part(native) {
	--ion-button-width: rem(56px);
	--ion-button-height: rem(56px);
}
