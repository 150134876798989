.modal {
	--background-opacity: 0.8;
	--width: 100%;
	--height: fit-content;
	--max-height: 100%;
	--background: transparent;
	--box-shadow: none;

	animation: blur-in 0.3s forwards;
}

.full-screen-modal {
	--width: 100%;
	--height: 100%;
}

.modal::part(content) {
	@apply container-1-col container-space;

	overflow-y: auto;
}

@keyframes blur-in {
	from {
		backdrop-filter: blur(0px);
	}
	to {
		backdrop-filter: blur(2px);
	}
}
