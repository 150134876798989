@layer components {
	.wysiwyg {
		/* ---------------------------------------------------------------------- */
		/* Headings */
		/* ---------------------------------------------------------------------- */

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		ul,
		ol,
		p {
			& + h1,
			& + h2,
			& + h3,
			& + h4,
			& + h5,
			& + h6,
			& + ul,
			& + ol,
			& + p {
				margin-top: theme("spacing.4");
			}
		}

		/* ---------------------------------------------------------------------- */
		/* Link */
		/* ---------------------------------------------------------------------- */

		a {
			@apply typo-link;

			--link-color: theme("colors.s-100");

			text-decoration: underline;

			color: var(--link-color);

			transition: 0.2s ease-in-out;

			&:hover {
				--link-color: theme("colors.s-200");
			}
		}

		p,
		ul,
		ol,
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			& + a {
				display: inline-block;

				margin-top: theme("spacing.4");
			}
		}

		/* ---------------------------------------------------------------------- */
		/* Lists */
		/* ---------------------------------------------------------------------- */

		ul,
		ol {
			padding-left: theme("spacing.5");
			margin-top: theme("spacing.4");
		}

		ul {
			list-style-type: disc;
		}

		li + li {
			margin-top: theme("spacing.2");
		}

		ol {
			list-style-type: none;
			counter-reset: item;
			margin: 0;
			padding: 0;
		}

		ol > li {
			display: table;
			counter-increment: item;
			margin-bottom: 0.6em;
		}

		ol > li:before {
			content: counters(item, ".") ". ";
			display: table-cell;
			padding-right: 0.6em;
		}

		li ol > li {
			margin: 0;
		}

		li ol > li:before {
			content: counters(item, ".") " ";
		}

		/* ---------------------------------------------------------------------- */
		/* Sub */
		/* ---------------------------------------------------------------------- */

		p sup {
			color: theme("colors.n-400");
		}

		/* ---------------------------------------------------------------------- */
		/* Table */
		/* ---------------------------------------------------------------------- */

		table {
			width: 100%;
			overflow: hidden;

			border-collapse: collapse;
		}

		thead {
			--thead-display: none;

			display: var(--thead-display);

			background-color: theme("colors.n-200");

			@media screen(lg) {
				--thead-display: table-header-group;
			}
		}

		tbody,
		thead {
			tr {
				border-bottom: 1.5px solid theme("colors.n-400");
			}

			th,
			td {
				--table-padding: 0;

				padding: var(--table-padding);

				text-align: left;
				vertical-align: bottom;

				@media screen(lg) {
					--table-padding: theme("spacing.4") theme("spacing.2");
				}
			}
		}

		tbody {
			tr + tr {
				--tbody-tr-display: block;
				--tbody-tr-mt: theme("spacing.6");

				display: var(--tbody-tr-display);
				margin-top: var(--tbody-tr-mt);

				@media screen(lg) {
					--tbody-tr-display: table-row;
					--tbody-tr-mt: 0;
				}
			}

			td {
				--tbody-td-display: flex;
				--tbody-td-border-top: 1.5px solid theme("colors.n-400");
				--tbody-td-padding: rem(16px);

				display: var(--tbody-td-display);
				padding: var(--tbody-td-padding);

				border-top: var(--tbody-td-border-top);

				@media screen(lg) {
					--tbody-td-display: table-cell;
					--tbody-td-border-top: none;
					--tbody-td-padding: theme("spacing.4") theme("spacing.2");
				}
			}

			td::before {
				@apply typo-body variant-body-fat;

				content: attr(data-head);

				display: block;
				flex-basis: rem(140px);
				flex-grow: 0;
				flex-shrink: 0;
				padding: rem(16px);
				margin: rem(-16px) rem(16px) rem(-16px) rem(-16px);

				background-color: theme("colors.n-200");

				@media screen(lg) {
					display: none;
				}
			}
		}
	}
}
