/* -------------------------------------------------------------------------- */
/* Ionic */
/* -------------------------------------------------------------------------- */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
/* @import '@ionic/angular/css/normalize.css'; */
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* -------------------------------------------------------------------------- */
/* Icons */
/* -------------------------------------------------------------------------- */
@import "@parkour/assets/fonts/icons.css";

/* -------------------------------------------------------------------------- */
/* Base */
/* -------------------------------------------------------------------------- */
@import "./base/typography.css";

/* -------------------------------------------------------------------------- */
/* Components */
/* -------------------------------------------------------------------------- */
@import "./components/wysiwyg.css";
@import "./components/header.css";

/* -------------------------------------------------------------------------- */
/* Utils */
/* -------------------------------------------------------------------------- */
@import "./utils/utils.css";

/* -------------------------------------------------------------------------- */
/* Tailwind */
/* -------------------------------------------------------------------------- */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* -------------------------------------------------------------------------- */
/* Fonts */
/* -------------------------------------------------------------------------- */
@layer base {
	@font-face {
		font-family: Flanders Art Sans;
		font-weight: theme("fontWeight.normal");
		font-style: normal;
		font-display: swap;

		src: url("@parkour/assets/fonts/FlandersArtSans-Regular.woff2") format("woff2");
	}

	@font-face {
		font-family: Flanders Art Sans;
		font-weight: theme("fontWeight.medium");
		font-style: normal;
		font-display: swap;

		src: url("@parkour/assets/fonts/FlandersArtSans-Medium.woff2") format("woff2");
	}
}

/* -------------------------------------------------------------------------- */
/* Application variables */
/* -------------------------------------------------------------------------- */
@layer base {
	:root {
		--app-sidebar-width: 0rem;
		--app-toolbar-height: rem(44px);
		--app-tabbar-height: rem(56.5px);
		--app-plus-global-header-height: 0rem;

		@media screen(lg) {
			--app-sidebar-width: rem(220px);
			--app-tabbar-height: 0rem;
		}
	}

	.global-header-margin {
		margin-top: var(--app-plus-global-header-height);
	}

	.ion-app-plus-global-header {
		top: var(--app-plus-global-header-height);
	}
}

/* -------------------------------------------------------------------------- */
/* Application layout */
/* -------------------------------------------------------------------------- */
body {
	@apply typo-body;

	color: theme("colors.n-1000");
}

ion-header:not(.modal-header, .full-header) {
	width: calc(100% - var(--app-sidebar-width)) !important;
	transform: translateX(var(--app-sidebar-width));
}

ion-content:not(.content-child, .modal-content) {
	@media screen(lg) {
		transform: translateY(calc(var(--app-toolbar-height) * -1));
	}

	&::part(background),
	&::part(scroll) {
		@media screen(lg) {
			min-height: calc(100% + var(--app-toolbar-height));
		}
	}
}

ion-tab-bar {
	@media screen(lg) {
		display: none !important;
	}
}

parkour-page-layout,
parkour-page-layout-sidebar {
	display: block;
}

parkour-page-layout-sidebar {
	height: 100%;
}

.page-layout {
	--page-layout-grid-template-columns: var(--app-sidebar-width) 1fr;

	@media screen(lg) {
		display: grid;
		grid-template-columns: var(--page-layout-grid-template-columns);
		grid-template-rows: var(--app-toolbar-height) calc(100vh - var(--app-toolbar-height) - var(--app-plus-global-header-height)) 1fr;
	}
}

.page-layout--full-width {
	--page-layout-grid-template-columns: 1fr;
}

/* -------------------------------------------------------------------------- */
/* Ionic application colors */
/* -------------------------------------------------------------------------- */
@layer base {
	:root {
		--ion-background-color: theme("colors.n-100");
		--ion-background-color-rgb: theme("colors.n-100-rgb");
		--ion-text-color: theme("colors.n-1000");
		--ion-text-color-rgb: theme("colors.n-1000-rgb");
		--ion-backdrop-color: theme("colors.n-100");
		--ion-backdrop-opacity: 0.8;
		--ion-overlay-background-color: theme("colors.n-100/80");
		--ion-border-color: theme("colors.n-500");
		--ion-toolbar-background: theme("colors.p-100");
		--ion-toolbar-color: theme("colors.n-1000");
		--ion-toolbar-segment-color: theme("colors.n-600");
		--ion-toolbar-segment-color-checked: theme("colors.n-1000");
		--ion-toolbar-segment-background: transparent;
		--ion-toolbar-segment-background-checked: transparent;
		--ion-toolbar-segment-indicator-color: theme("colors.n-1000");
		--ion-item-background: transparent;
		--ion-item-border-color: theme("colors.n-500");
		--ion-item-color: theme("colors.n-1000");
		--ion-placeholder-color: theme("colors.n-1000/70");
		--ion-font-family: theme("fontFamily.flandersArtSans");
		--avatar-border-color: theme("colors.n-300");
		--notification-outline-color: theme("colors.n-100");
	}
}

/* -------------------------------------------------------------------------- */
/* Ionic safe area */
/* -------------------------------------------------------------------------- */
ion-header:empty {
	padding-top: var(--ion-safe-area-top);
}

/* -------------------------------------------------------------------------- */
/* Ionic toast */
/* -------------------------------------------------------------------------- */
ion-toast {
	--border-radius: theme("borderRadius.DEFAULT");
	--color: theme("colors.n-0");
	--button-color: theme("colors.n-0");

	height: calc(100% - var(--app-tabbar-height) - rem(16px));

	@media screen(lg) {
		height: 100%;
	}
}

ion-toast::part(container) {
	@apply typo-body;
}

ion-toast::part(header) {
	@apply typo-body variant-body-fat;
}

ion-toast::part(message) {
	@apply typo-body;
}

.ion-toast--default {
	--background: theme("colors.positive");
}

.ion-toast--succeservaring {
	--background: theme("colors.s-100");
}

.ion-toast--error {
	--background: theme("colors.negative");
}

#toast-anchor {
	position: absolute;
	top: var(--header-height);
	left: 0;
	right: 0;
}

/* -------------------------------------------------------------------------- */
/* Ionic infinite scroll */
/* -------------------------------------------------------------------------- */
ion-infinite-scroll-content {
	min-height: rem(34px);
}

.infinite-loading {
	margin-bottom: 0;
}

/* -------------------------------------------------------------------------- */
/* Ionic toolbar */
/* -------------------------------------------------------------------------- */
.md {
	& ion-toolbar {
		--min-height: var(--app-toolbar-height);
	}
}

/* -------------------------------------------------------------------------- */
/* Ionic toast */
/* -------------------------------------------------------------------------- */
ion-toast {
	width: calc(100% - var(--app-sidebar-width));

	transform: translateX(var(--app-sidebar-width));
}

/* -------------------------------------------------------------------------- */
/* Swiper */
/* -------------------------------------------------------------------------- */
.custom-swiper::part(container) {
	overflow: visible;

	padding-bottom: rem(48px);
	margin-bottom: rem(24px);
}

.custom-swiper::part(pagination) {
	bottom: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	pointer-events: none;
}

.custom-swiper::part(bullet),
.custom-swiper::part(bullet-active) {
	width: rem(8px);
	height: rem(8px);
	margin: 0 rem(2px);

	transition: transform 0.2s ease-in-out,
	opacity 0.2s ease-in-out,
	background-color 0.2s ease-in-out;
}

.custom-swiper::part(bullet) {
	background-color: theme("colors.s-300");
	opacity: 0.5;

	transform: scale(0.8);
}

.custom-swiper::part(bullet-active) {
	background-color: theme("colors.s-100");

	transform: scale(1);
}

.custom-swiper::part(button-next),
.custom-swiper::part(button-prev) {
	--swiper-button-background: transparent;
	--swiper-button-border: theme("colors.s-100");

	top: calc(100%);

	display: flex;
	align-items: center;
	justify-content: center;
	width: rem(16px);
	height: rem(16px);
	padding: theme("spacing.2");

	color: theme("colors.s-100");
	border: 1.5px solid var(--swiper-button-border);
	border-radius: theme("borderRadius.DEFAULT");
	background-color: var(--swiper-button-background);

	transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out;

	&:hover,
	&:focus-visible {
		--swiper-button-background: theme("colors.s-200/0.20");
		--swiper-button-border: theme("colors.s-200");
	}
}

/* -------------------------------------------------------------------------- */
/* Accessibility */
/* -------------------------------------------------------------------------- */
@media (prefers-reduced-motion: no-preference) {
	html {
		scroll-behavior: smooth;
	}

	*:focus-visible {
		transition: outline-offset .15s linear;
	}
}

swiper-container::part(button-next):focus,
swiper-container::part(button-prev):focus,
ion-input.has-focus,
ion-button.ion-focused,
ion-radio.ion-focused,
ion-select.ion-focused,
ion-fab.ion-focused,
ion-searchbar.ion-focused,
ion-checkbox.ion-focused,
ion-toggle.ion-focused,
ion-range.ion-focused,
ion-datetime.ion-focused,
ion-accordion.ion-focused,
ion-textarea.ion-focused,
ion-back-button.ion-focused,
ion-tab-button.ion-focused,
ion-segment-button.ion-focused,
[type="button"]:focus-visible,
[type="submit"]:focus-visible,
button:focus-visible,
a:focus-visible,
iframe:focus-visible,
summary:focus-visible,
input.is-focused,
textarea.is-focused,
select.is-focused,
[contentEditable=true].is-focused {
	--outline-offset: 3px;

	outline: 3px dashed yellow;
	outline-offset: var(--outline-offset);
	overflow: visible;
}

ion-back-button.ion-focused,
ion-segment-button.ion-focused {
	--outline-offset: -3px;
}

parkour-header-action {
	ion-button.ion-focused {
		--outline-offset: -3px;
	}

	ion-button.ion-focused::part(native)::after {
		content: none;
	}
}

/*Hide default outline for inputs because focus-visible still shows them*/
input,
textarea,
select,
[contentEditable=true] {
	outline: none;
}

/* -------------------------------------------------------------------------- */
/* Misc */
/* -------------------------------------------------------------------------- */
input[type="date"] {
	color-scheme: dark;
}

input[type="time"]::-webkit-calendar-picker-indicator {
	display: inline-block !important;
	opacity: 0 !important;
}

/**
 * Fix for the image-cropper where an image uploaded smaller then the viewport size is not shown correctly.
 * This fix centers the image so the image is visible in the image-cropper.
 */
.ngx-ic-source-image {
	margin-left: 50%;

	transform: translateX(-50%);
}

/**
 * Added for native qr scanning to work
 */
body.scanner-active {
	--background: transparent;
	--ion-background-color: transparent;
}

/**
 * Fix popover outline a11Y
 */
.popover-viewport {
	overflow: visible;
}

/**
 * Fix dynamic tailwind change css var
 */
.avatar-border-active {
	--avatar-border-color: theme("colors.p-100");
}

/**
 * VO header
 */
.vlw .vlw__toaster__item__title {
	color: theme("colors.n-100") !important;
}

/**
 * ion-popover
 */
ion-popover {
	--background: transparent;
	--box-shadow: transparent;
	--width: auto;
	--height: auto;
}

ion-popover.parkour-popover--fill-space::part(content) {
	--space: theme("spacing.4");

	left: 0 !important;

	width: calc(100% - (2 * var(--space)));
	padding-left: var(--space);
	padding-right: var(--space);
}

.bericht-message-content {
	white-space: pre-wrap;
	word-break: break-word;
}

ion-content::part(scroll),
.scrollbar {
	scrollbar-color: theme("colors.n-1000-rgb-3") transparent;
}

/**
 * details
 */
summary {
	list-style: none;
}


summary::-webkit-details-marker {
	display: none;
}