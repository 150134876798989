ion-toggle {
	--handle-background: theme("colors.n-500");
	--handle-background-checked: theme("colors.n-500");
	--track-background: transparent;
	--track-background-checked: theme("colors.positive");
	--handle-height: 0.75rem;
	--handle-max-height: 0.75rem;
	--handle-width: 0.75rem;
	--handle-box-shadow: none;
	--handle-spacing: 0.25rem;
}

ion-toggle::part(track) {
	--track-border-color: theme("colors.n-500");

	width: 2.3125rem;
	height: 1.3125rem;

	border: 1.5px solid var(--track-border-color);
}

ion-toggle.toggle-checked::part(track) {
	--track-border-color: theme("colors.positive");
}