@import "./parkour-checkbox/parkour-checkbox.component.css";
@import "./parkour-image/parkour-image.component.css";
@import "./parkour-segment/parkour-segment.component.css";
@import "./parkour-radio/parkour-radio.component.css";
@import "./parkour-modal/parkour-modal.component.css";
@import "./parkour-popup/parkour-popup.component.css";
@import "./parkour-toggle/parkour-toggle.component.css";
@import "./parkour-button/parkour-button.component.css";

/* Styling for dynamic modals */
.popup.dynamic {
	--background-opacity: 0.8;
	--width: 100%;
	--height: fit-content;

	animation: blur-in 0.3s forwards;
}

.popup.dynamic::part(content) {
	@apply container-space;
}

@keyframes blur-in {
	from {
		backdrop-filter: blur(0px);
	}
	to {
		backdrop-filter: blur(2px);
	}
}
