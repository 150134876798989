ion-segment {
  display: flex;
  gap: theme("spacing.4");
}

ion-segment-button {
  --ripple-color: transparent;
  --color: theme("colors.n-600");
  --color-checked: theme("colors.n-1000");
  --color-focused: theme("colors.n-1000");
  --color-hover: theme("colors.n-1000");
  --indicator-color: theme("colors.n-1000");
  --indicator-height: 1.5px;
  --indicator-box-shadow: none;
  --background: transparent;
  --background-hover: transparent;
  --background-checked: transparent;
  --background-focused: transparent;
  --padding-top: theme("spacing.2");
  --padding-bottom: theme("spacing.2");
  --padding-start: theme("spacing.2");
  --padding-end: theme("spacing.2");
}

ion-label {
  margin: 0;

  text-transform: initial;
  letter-spacing: normal;
}
